import qs from 'qs';

export const getParams = search =>
  qs.parse(search, {
    ignoreQueryPrefix: true,
  });

export const getSearch = params => qs.stringify(params);

export const mergeParams = (url, params) => {
  const baseUrl = new URL(url, window.location);
  const searchParams = new URLSearchParams(params);
  const mergedParams = new URLSearchParams({
    ...Object.fromEntries(baseUrl.searchParams),
    ...Object.fromEntries(searchParams),
  });
  const paramString = mergedParams.toString();
  const searchString = paramString ? `?${paramString}` : '';
  return `${baseUrl.origin}${baseUrl.pathname}${searchString}`;
};
